@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cuprum:ital,wght@0,400..700;1,400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aldrich&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

.custom-navbar {
  background: linear-gradient(90.6deg, #1d1918 -56.25%, #ff5906 103.4%);
  height: 80px;
  padding: 0 72px;
}

.custom-catch {
  font-family: "Cuprum", sans-serif;
  font-weight: 800;
}

.nav-link-home {
  color: white !important; /* Ensure text color is visible on the dark background */
}

.navbar-brand-text {
  color: white; /* Ensure brand text color is visible on the dark background */
}

.custom-accordion-button {
  background-color: #ffeee6 !important;
  border: none;
}

/* Default style for the accordion button */
.accordion-button {
  background-color: #ffeee6; /* Default background color */
  color: #000; /* Default text color */
  border: 1px solid #ddd; /* Optional: border color */
}

/* Style for the accordion button when it is active */
.accordion-button:not(.collapsed) {
  background-color: #ff5906; /* Active background color */
  color: #333; /* Active text color */
  border: 1px solid #ff5906; /* Optional: active border color */
}
