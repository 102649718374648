@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cuprum:ital,wght@0,400..700;1,400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aldrich&display=swap");

.row > * {
  margin-top: 0 !important;
  padding-bottom: calc(var(--bs-gutter-x) * 0.5);
  padding-top: calc(var(--bs-gutter-x) * 0.5);
}

.layout-color {
  background-color: #fff7f3;
}
.Layout {
  padding: 0px 72px 0px 72px;
}

.custom-container {
  padding: 0 72px;
}

.nav-background {
  background: #160702;
  width: 100%;
  padding: 0 72px;
}

.background-image {
  /* background-image: url("../../assets/images/banner/Main.png");  */
  background: linear-gradient(180deg, #000000 -21.19%, #ff5906 231.34%);
  /* linear-gradient(180deg, #311101, #692402 100%); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh; /* Adjust this if you want it to cover the entire viewport height */
  padding: 0 72px;
}
.banner-container {
  background-size: cover;
  background-position: center;
  /* height: 100vh;  */
  /* Adjust height as needed */
  text-align: center;
}

.navbar-brand-text {
  font-family: "Cuprum", sans-serif;
}

.nav-text {
  font-family: "Cuprum", sans-serif;
}

.text-center {
  position: relative;
  height: 100vh; /* Ensures the parent container covers the entire viewport height */
}

.custom-bannertext {
  position: absolute;
  top: 50%;
  width: 80%;
  font-family: "Cuprum", sans-serif;
}

.custom-text {
  font-size: 48px;
  font-family: "Aldrich", sans-serif; /* Ensure Aldrich font is imported */
  font-weight: 400;
}

.paragraph {
  padding: 0 13px;
}

.indias {
  float: right;
}

.custom-text-2 {
  font-size: 48px;
  font-family: "Aldrich", sans-serif; /* Ensure Aldrich font is imported */
  font-weight: 400;
}

.custom-text-3 {
  font-size: 19px;
  font-family: "Cuprum", sans-serif; /* Ensure Aldrich font is imported */
  font-weight: 400;
  line-height: normal;
}

.custom-btn {
  color: #fff;
  --bs-btn-hover-bg: #ff5906;
  --bs-btn-hover-color: #fff;
  border-color: #ff5906 !important;
  background-color: #ff5906 !important;
  border-radius: 10px;
  margin-top: 20px;
  font-size: 19px;
  padding: 4px 24px;
}

/* Optional: You can also set the hover state explicitly if needed */
.custom-btn:hover {
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-bg);
}

.text-center {
  position: relative;
  height: 100vh;
}
/* .card-img-custom{
  background-image: url('../../assets/images/card.jpg'); /* Adjust this path as needed */
/* background-size: cover; Ensures the image covers the entire div */
/* background-position: center; Centers the image */
/* height: 172px; Set the desired height */
/* width: 50%; */
/* Ensure it spans the full width of its container */

/* } */

.artistsContianer {
  background: linear-gradient(180deg, #fff7f3 0%, #ffb48f 58%, #fff7f3 100%);
  padding: 48px 72px 0;
}

.container {
  width: 100%;
}

.custom-power {
  font-size: 33px;
  font-family: "Cuprum", sans-serif; /* Ensure Aldrich font is imported */
  font-weight: 700;
}

.custom-card {
  width: 18rem;
  /* height: 20rem; */
  border-radius: 24px;
  background-color: #333;
  color: white;
  overflow: hidden;
}

.custom-card-team {
  width: 95%;
  padding: 25px;
  align-items: center;
  background-color: #fef4ea !important;
  border: #fef4ea !important;
  border-radius: 24px !important;
}

.card-img-custom {
  width: 172px;
  height: 172px;
  background-color: #ff935d;
  border-radius: 24px;
}

.listcard-title {
  font-family: "Cuprum", sans-serif;
  font-weight: 700;
  color: #ff5906 !important;
  width: 60%;
  /* color: #ffffff; */
}

.card-text {
  font-weight: 400;
  font-size: 19px;
  line-height: 21.95px;
  font-family: "Cuprum", sans-serif;
  height: auto;
  width: 100%;
}

.card-img-top {
  background-color: #ff935d;
  display: flex;
  justify-content: space-between;
}

.card-body {
  padding: 0;
}

.card-number {
  background-color: #ff5906;
  color: #fff;
  display: block;
  width: 46px;
  height: 46px;
  border-radius: 16px;
  align-content: center;
  text-align: center;
  /* width: 15%;
  margin-left: 20px;
  margin-top: 40px;
  padding: 4px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center; */
  color: #fff;
}

.card-body-custom {
  background-color: #ff935d;
  color: #ffffff;
  border-radius: 24px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.text-white {
  color: #fff;
}

.logoFont {
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-weight: 700;
  font-style: normal;
  color: #fff;
}

.logoLink {
  color: white;
  width: 30%;
  display: flex;
}
.nav-link-home {
  color: #fff !important;
}

.navbar-toggler-icon {
  color: #ffffff;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.text-center {
  text-align: center;
  height: auto;
}

.text-white {
  color: #fff;
}

.navbar-brand {
  display: flex;
  align-items: center;
  text-decoration: none; /* Optional: to remove underline on hover */
}

.navbar-brand-text {
  color: #fff;
  font-size: 32px;
  font-weight: bolder;
  font-family: "Poppins";
}

.position-relative {
  position: relative;
  height: 100%; /* Ensures the parent container covers the entire height */
}

.position-absolute {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0; /* Adjust this value as needed */
  width: 100%;
}

.text-section {
  /* padding: 1px 20px; */
  /* background-color: #f8f9fa; Optional: adds a light background color */
  margin-top: 2rem;
  margin-bottom: 3rem;
}

.text-section h2 {
  font-size: 48px;
  font-family: "Cuprum", sans-serif; /* Ensure Aldrich font is imported */
  margin-bottom: 20px;
}

.text-section p {
  font-size: 23px;
  color: #6c757d;
  max-width: 100%;
  margin: 0 auto;
  line-height: 26.57px;
  /* background-color: #fff7f3; */
}

.highlight {
  color: #ff5906; /* Orange color for highlighted words */
}

.text-muted {
  font-family: "Cuprum", sans-serif;
  font-size: 23px;
}
.listcard-img-custom {
  width: 80%;
  margin-left: 20px;
  margin-top: 20px;
  background-color: #fff7f3;
  border-radius: 24px;
  height: 167px;
  object-fit: cover;
}
.listcardfour-img-custom {
  width: 50%;
  margin-left: 20px;
  margin-top: 20px;
  background-color: #fff7f3;
  border-radius: 24px;
  height: 167px;
  object-fit: cover;
}
.listcardfive-img-custom {
  width: 50%;
  margin-left: 20px;
  margin-top: 20px;
  background-color: #fff7f3;
  border-radius: 24px;
  height: 167px;
  object-fit: cover;
}

.label-inside {
  position: absolute;
  top: 10px;
  left: 12px;
  background-color: white;
  padding: 0 5px;
  font-size: 0.875rem;
  color: #6c757d;
}
.border {
  border-radius: 4px;
  background-color: #fff;
}

.Custom-articles {
  font-family: "Cuprum", sans-serif;
  font-size: "48px";
}

.radio-box {
  /* App.css */
  border: 2px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  background-color: #ffffff;
}

.articlescard-title {
  font-family: "Cuprum", sans-serif;
  font-weight: 700;
  font-size: 33px;
  color: #ffffff;
  /* margin-left: 6.5rem; */
  margin-top: 1.5rem;
}

/* .articles-card {
  background-color: #ff935d;
  max-width: 100%;
  border-radius: 24px;
  height: auto;
} */

.articlescard-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 1rem;
  margin-left: 1rem;
}

.articlescard-text {
  margin-bottom: 1rem;
  color: #ffff;
  font-size: 19px;
  line-height: 21.95px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
}
.text-left {
  margin-left: 6rem;
}
.articlescontainer {
  padding: 0px;
}

.teamimg-custom {
  width: 50%;
}

.customArticleRow {
  padding: 32px;
  display: flex;
}

.teamcard-img-custom {
  width: 60%;
  /* margin-left: 50px;
  margin-top: 17px; */
  background-color: #ff935d;
  border-radius: 24px;
  height: 30vh;
  margin-bottom: 15px;
  background-color: transparent;
}

.team-card-text {
  font-weight: 400;
  line-height: 21.95px;
  font-family: "Cuprum", sans-serif;
  color: #ff5906;
  font-size: 19px;
  /* padding: 0px; */
  /* margin-top: 3rem; */
  /* margin-bottom: 1rem; */
  margin-left: 7px;
}

.team-role-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 21.95px;
  font-family: "Cuprum", sans-serif;
  margin-bottom: 0rem;
  margin-left: 7px;
  color: #333;
}

/* .teamcard-img-top {
  background-color: #fff;
} */

.teamcard-body-custom {
  /* background-color: #ffff;
  color: #ffffff;
  border-radius: 24px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px; */
  text-align: center;
}

.linkedin-img-cudtom {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 24px; /* Adjust size as needed */
  height: 24px; /* Adjust size as needed */
}

.responsive-card {
  /* width: 100%; */
  max-width: 100%;
}

.small-radio {
  transform: scale(0.7); /* Adjust the scale to resize the radio buttons */
}

/* .article-img {
  width: -webkit-fill-available;
} */

.read-more-btn {
  background-color: #ff5906 !important;
  color: white !important;
  border: #ff5906 !important;
}

.animate-container {
  color: #ff5906;
  font-size: 36px;
  text-transform: uppercase;
  /* height: 100vh; */
  display: flex;
  /* align-items: center; */
  justify-content: center;
}

.animate-span {
  color: white;
}

.animation {
  height: 50px;
  overflow: hidden;
}

.animation > div > div {
  padding: 0rem 0.75rem;
  height: 50px;
  width: 145px;
  text-align: end;
  display: inline-block;
}

.animation-2 div:first-child {
  animation: text-animation 6s infinite;
}

.animation-2 {
  height: 50px;
  overflow: hidden;
}

.animation-2 > div > div {
  padding: 0rem 0.75rem;
  height: 50px;
  width: 225px;
  text-align: start;
  display: inline-block;
}

.animation div:first-child {
  animation: text-animation 6s infinite;
}

@keyframes text-animation {
  10% {
    transform: translate(-112%);
  }
  25% {
    transform: translate(-100%);
  }
  35% {
    transform: translate(-212%);
  }
  50% {
    transform: translate(-200%);
  }
  60% {
    transform: translate(-312%);
  }
  75% {
    transform: translate(-300%);
  }
  85% {
    transform: translate(-412%);
  }
  100% {
    transform: translate(-400%);
  }
  /* 0% {margin-top: 0;}
  10% {margin-top: 0;}
  20% {margin-top: -55px;}
  30% {margin-top: -55px;}
  90% {margin-top: 0;}
  100% {margin-top: 0;} */
}

.wrapperForSpin {
  height: 70px;
  display: flex;
  justify-content: center;
  /* font-family: "aldrich"; */
}
.spinWords {
  overflow: hidden;
}
.spinSpan {
  color: #ff5906;
  display: block;
  /* font-size: 48px; */
  height: 100%;
  animation: spin_words 8s infinite;
}

@keyframes spin_words {
  0%,
  25% {
    transform: translateY(0%);
  }
  50% {
    transform: translateY(-100%);
  }
  62.5% {
    transform: translateY(-100%);
  }
  75% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

.form-check-input:checked {
  background-color: #ff5906 !important;
  border-color: #ff5906 !important;
}

.notify-btn {
  width: 100%;
  background-color: #ff5906;
  color: white;
  border: #ff5906;
  border-radius: 25px;
  padding: 8px 0px;
  font-family: "Cuprum";
  font-size: 19px;
  height: 48px;
}

.notified-btn{
  width: 20%;
  background-color: #ff5906;
  color: white;
  border: #ff5906;
  border-radius: 25px;
  padding: 8px 0px;
  font-family: "Cuprum", sans-serif;
  font-size: 19px;
  /* height: 48px; */
  float: right;
}

.tarana-listeners-section {
  background: linear-gradient(180deg, #fff7f3 0%, #ffb48f 58%, #fff7f3 100%);
}

.experience-section {
  /* width: 100%; */
  background-color: #ff935d;
  border-radius: 24px;
  margin-top: 32px;
  padding: 24px;
}

.custom-input {
  font-family: "Cuprum", sans-serif;
}

.team-section {
  background: linear-gradient(180deg, #fff7f3 13%, #ffb48f 100%);
}

.form-control {
  height: 100%;
}

.form-group {
  background: #f5f5f5;
  padding: 0.375rem 0.75rem;
  display: flex;
  justify-content: space-between;
}

.prerequest {
  background: #ff935d;
  border-radius: 24px;
  color: #fff;
  padding: 24px;
}

.notifyme {
  display: flex;
  justify-content: space-between;
}

.notifyme > div > input {
  height: 40px;
  width: max-content;
}

.articlesRow {
  display: flex;
  flex-direction: row;
}

.customm-card {
  border-radius: 24px !important;
  background: #ff935d !important;
  color: #ffffff !important;
  display: flex !important;
  flex-direction: row !important;
  padding: 32px !important;
  gap: 20px !important;
  font-family: "Cuprum", sans-serif;
}

.custom-card-bodyy {
  width: 55%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.customm-heading {
  font-size: 23px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Limit to 3 lines */
  -webkit-box-orient: vertical;
}

.custom-notify-btn-div {
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

.custom-type-div {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff0e9;
  flex-grow: 1;
  padding: 0 24px;
  border-radius: 25px;
  height: 48px;
  font-size: 19px;
  min-width: 80px;
  font-family: "Cuprum", sans-serif;
}

@media screen and (min-width: 1268px) and (max-width: 1328px) {
  /* Your CSS rules here */
  .customm-heading{
    -webkit-line-clamp: 2;
  }
}

@media (max-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100%;
  }
  .notified-btn{
    width: 30%;
  }
}

@media only screen and (max-width: 1007px) {
  .nav-background {
    padding: 0 32px;
  }

  .background-image {
    padding: 0 32px;
  }

  .wrapperForSpin {
    height: 58px;
  }

  .custom-text {
    font-size: calc(1.375rem + 1.5vw) !important;
  }
}
@media screen and (max-width: 991px) {
  /* .custom-type-div {
    width: 100% !important;
  } */

  .custom-notify-btn-div, .notify-btn{
    width: 100% !important;
  }
}

@media only screen and (max-width: 900px) {
  .artistsContianer {
    padding: 48px 32px 0;
  }

  .Layout {
    padding: 0px 32px;
  }

  .articlesRow {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 768px) {
  .wrapperForSpin {
    height: 45px;
  }

  .card-img-custom {
    width: 198px !important;
    height: 198px !important;
  }

  .animation > div > div {
    text-align: center !important;
  }

  .animation-2 > div > div {
    text-align: center !important;
  }

  .col-12.col-md-6 {
    width: 100% !important;
  }

  .customm-card {
    padding: 24px !important;
    gap: 15px !important;
  }

  .custom-card-team {
    width: 300px;
  }

  .custom-card-bodyy {
    width: 100%;
  }

  .customm-card img {
    width: 90%; /* Reduce image size */
    height: auto;
  }
}

@media only screen and (max-width: 576px) {
  .nav-background {
    padding: 0 16px;
  }

  .background-image {
    padding: 0 16px;
  }

  .card-img-custom {
    width: 262px !important;
    height: 262px !important;
  }

  .animate-container {
    display: block !important;
  }

  .indias {
    float: none;
  }

  .Layout {
    padding: 0px 16px 0px 16px;
  }

  .custom-bannertext {
    padding: 16px;
  }

  .wrapperForSpin {
    display: flex;
    flex-direction: column;
    height: 125px;
    /* font-family: "aldrich"; */
  }

  .paragraph {
    margin: 0;
  }

  .custom-text {
    font-size: 33px;
  }

  .custom-text-3 {
    font-size: 19px;
  }

  .text-section h2 {
    font-size: 40px;
  }

  .text-section p {
    font-size: 19px;
    line-height: normal;
  }

  .artistsContianer {
    padding: 48px 16px 0;
  }

  .customm-card {
    flex-direction: column !important;
    text-align: center;
  }

  .custom-card-bodyy {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
  }

  .customm-card img {
    width: 100%;
  }

  .articlescard-text {
    text-align: left;
    font-size: 16px;
  }

  .notified-btn{
    width: 100%;
  }
}

@media only screen and (max-width: 376px) {
  .card-img-custom {
    width: 198px !important;
    height: 198px !important;
  }
}

@media only screen and (max-width: 320px) {
  .background-image {
    height: 140vh;
  }
}
