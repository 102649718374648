@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cuprum:ital,wght@0,400..700;1,400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aldrich&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

.custom-contact{
    width: 100%;
}
/* Base style for the input box */
.formcontrol-custom {
    /* other styles you might have */
}
.custom-us{
    color: #ff5906;
}

.form-control{
    background-color: #FFF0E9;
    border-color: #ff5906;
}

@media (max-width: 576px) { /* Target mobile screens (extra-small devices) */
    .container-fluid {
        margin-left: 0 !important; /* Remove left margin */
        margin-right: 0 !important; /* Optionally remove right margin as well */
    }
}
      
/* Styles for desktop screens */
@media (min-width: 992px) { /* Adjust the breakpoint as needed */
    .formcontrol-custom {
        height: 325px; /* Fixed height for larger screens */
    }
}

@media (min-width: 992px) { /* Adjust the breakpoint as needed */
    .custom-contact {
        width: 937px; /* Fixed width for larger screens */
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width: 767px) { /* Adjust the breakpoint as needed */
    .formcontrol-custom {
        width: 364px;  /* Fixed width for mobile screens */
        height: 325px; /* Fixed height for mobile screens */
    }
}

@media (min-width: 768px) and (max-width: 1024px) { 
    .formcontrol-custom {
        width: 616px;  /* Fixed width for tablet screens */
        height: 325px; /* Fixed height for tablet screens */
        max-width: 100%; /* Ensure it doesn't overflow the screen */
        margin-left: auto;
        margin-right: auto;
    }
}

@media (max-width:1024px){
    .custom-contact{
        width: 50%;
    }
}

