@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cuprum:ital,wght@0,400..700;1,400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aldrich&display=swap");

.aboutcustom-card {
  width: 18rem;
  /* height: 20rem; */
  border-radius: 24px;
  color:rgba(255, 222, 205, 1); 
  overflow: hidden;
}

.custom-navbar {
  background: linear-gradient(90.6deg, #1d1918 -56.25%, #ff5906 103.4%);
  /* height: 80px; */
  padding: 0 72px !important;
}

.custom-catch {
  font-family: "Cuprum", sans-serif;
  font-size: 23px;
  font-weight: 800;
  padding: 0 72px 30px;
}

.nav-link-home {
  color: white !important; /* Ensure text color is visible on the dark background */
}

.navbar-brand-text {
  color: white; /* Ensure brand text color is visible on the dark background */
}

.main-article-section {
  background-color: #fff7f3;
  padding: 0 72px !important;
}

.custom-articlesbanner {
  /* border-radius: 24px; */
  width: 100%; /* Make the width responsive */
  max-height: 500px;
  /* max-width: 1299px;  */
  /* Ensure the image does not exceed this width */
  /* Maintain the specified height */
  object-fit: cover; /* Ensure the image covers the area without distortion */
}

.custom-titletext {
  font-size: 48px;
  font-family: "Cuprum", sans-serif;
  font-weight: 700;
  color: #ff5906;
  padding: 24px 72px;
}

.custom-headingText {
  font-size: 23px;
  font-family: "Cuprum", sans-serif;
  font-weight: 800;
  line-height: normal;
  padding: 0 72px;
}
.custom-paratext {
  font-size: 19px;
  font-family: "Cuprum", sans-serif;
  font-weight: 400;
  line-height: normal;
  padding: 0 72px;
}

/* @media (min-width: 768px) and (max-width: 1024px) {
  .custom-articlesbanner {
    width: 680px;
    height: auto;
    border-radius: 16px;
  }
} */

@media (max-width: 1007px) {
  .custom-navbar {
    background: linear-gradient(90.6deg, #1d1918 -56.25%, #ff5906 103.4%);
    height: auto !important;
    padding: 0 32px !important;
  }

  .custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }

  .navbar-toggler:focus{
    box-shadow: none !important;
  }

  .nav-item{
    text-align: center;
  }

  .custom-titletext {
    font-size: 44px;
    padding: 24px 32px;
  }

  .custom-headingText {
    padding: 0 32px;
  }

  .custom-paratext {
    padding: 0 32px;
  }

  .custom-catch {
    padding: 0 32px 30px;
  }
}

@media (max-width: 767px) {
  .custom-alltexts {
    margin-left: auto;
  }
}

@media (max-width: 576px) {
  .custom-navbar {
    /* background: linear-gradient(90.6deg, #1D1918 -56.25%, #FF5906 103.4%); */
    /* height: 80px; */
    padding: 0 16px !important;
  }

  .custom-titletext {
    font-size: 40px;
    padding: 24px 16px;
  }

  .custom-headingText {
    padding: 0 16px;
  }

  .custom-paratext {
    padding: 0 16px;
  }

  .custom-catch {
    padding: 0 16px 30px;
  }
}