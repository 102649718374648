@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cuprum:ital,wght@0,400..700;1,400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aldrich&display=swap");

/* Custom CSS for Footer */
/* Custom CSS for Footer */

.custom-tarana {
  font-family: "Cuprum", sans-serif;
  font-size: 19px;
}

.custom-tarana-title {
  font-size: 32px;
  font-weight: bolder;
  margin-bottom: 0;
  font-family: "Poppins";
}

.tarana-color {
  color: #ff5906;
}

.links-font {
  font-family: "Cuprum", sans-serif;
  font-size: 16px;
}

/* .custom-company{
  font-family: 'Cuprum', sans-serif; 
} */
/* .custom-para{
  font-family: 'Cuprum', sans-serif;
} */
.footer-two {
  margin: 0 auto;
}

.footer-bottom-text {
  border-top: 1px solid #444;
  padding-top: 10px;
  margin-top: 10px;
  font-family: "Cuprum", sans-serif;
}

.footer-three {
  /* display: flex;
  flex-direction: column; */
  justify-content: space-between;
  height: 100%;
  display: inline-grid;
  justify-content: flex-end;
}

.contact-info-wrapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.supported-by {
  display: flex;
  /* align-items: center; */
  flex-direction: column;
}

.footer-main{
  padding: 0 72px;
}

@media (max-width: 991px) {
  .footer-three {
    display: block;
  }
}

@media (max-width: 767px) {
  .footer-main{
    padding: 0 32px;
  }

  .footer-one{
    width: 50% !important;
  }
  
  .footer-two{
    margin: 0;
    width: 50% !important;
  }

  .footer-bottom-text {
    border-top: none;
    padding-top: 0;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 576px) {
  .footer-one {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100% !important;
  }

  .social-icons{
    justify-content: center;
  }

  .custom-justify {
    justify-content: center;
  }

  .footer-three {
    text-align: center;
    margin-top: 20px;
    margin-left: 0;
    justify-content: center;
  }

  .footer-two {
    text-align: center;
    margin-top: 20px;
    width: 100% !important;
  }

  .supported-by {
    display: block;
    text-align: center;
  }

  .links-font {
    text-align: center;
  }
}
