@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cuprum:ital,wght@0,400..700;1,400..700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aldrich&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");


.custom-cond{
    font-family: "Cuprum", sans-serif;
    color: #FF5906;
}
/* Base styles for larger screens */
.container {
    font-family: "Cuprum", sans-serif;
    line-height: 38.12px;
    font-weight: 400;
    font-size: 33px;
    width: auto;
}

/* Styles for mobile devices */
@media (max-width: 767px) { /* You can adjust this breakpoint as needed */
    .container {
        font-family: "Cuprum", sans-serif;
        line-height: 27.72px; /* Mobile-specific line height */
        font-weight: 400;     /* Mobile-specific font weight */
        font-size: 24px;      /* Mobile-specific font size */
        width: auto;
    }
}

